import { refreshApiKeyListApi } from '@/hooks/api/useOrganizationApi';
import {
  refreshBillingDetails,
  refreshInvoiceHistory,
  refreshSubscriptionPlanSettings,
  useSubscriptionPlanSettings,
} from '@/hooks/api/useSubscriptionApi';
import { refreshApiKey } from '@/hooks/api/useUserApi';
import { useSSEEventListener, useSSESubscription } from '@/hooks/useSSE';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { useGetProjectsQuery } from '@/serverStore/projects';
import { useAppDispatch } from '@/store';
import { Typography } from '@material-ui/core';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import CLEF_PATH, { isLegacyOrganizationLevelPath } from '../../constants/path';
import { refreshLabeledMediaCount } from '../../hooks/api/useDatasetApi';
import { ClientFeatures, useFeatureGateEnabled } from '../../hooks/useFeatureGate';
import { useOnVisibilityChange } from '../../hooks/useOnVisibilityChange';
import {
  useShowFreeTrialExpiredDialog,
  useShowFreeTrialWelcomeDialog,
} from '../../hooks/useSubscriptions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import ErrorPage from '../../pages/error/ErrorPage';
import {
  fetchEnabledFeaturesForOrg,
  fetchEnabledFeaturesForUser,
} from '../../store/feature_toggle_store';
import { fetchOrgUserProfile } from '../../store/newLoginState/actions';
import { LoginState } from '../../store/types';
import { fetchUsers } from '../../store/user_store';
import LiveWebinar from '../LiveWebinar';
import AppProjectRouter from './AppProjectRouter';
import FloatingActionButton from './components/FloatingActionButton';
import FreeTrialExpiredDialog from './components/FreeTrialExpiredDialog';
import FreeTrialWelcomeDialog from './components/FreeTrialWelcomeDialog';
import PricingV2MigrationConfirmationDialog from './components/PricingV2MigrationConfirmationDialog';
import { AppLoadingSplash, AppMaintenanceSplash } from './components/Splash';
import { PageLayoutRoot } from './PageLayout';
import organizationLevelRoutes from './routes/OrganizationLevelRoutes';
import { transformLegacyUrlWithOrgId } from './utils';

const AppRouter: React.FC<{}> = () => {
  const { path: currentPath } = useRouteMatch();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const me = useTypedSelector(state => state.login.user) as LoginState['user'];
  const { isOrgUserAuthenticated, isAuthenticated } = useTypedSelector(state => state.login);
  const { data: projects } = useGetProjectsQuery();
  const selectedProjectId = useTypedSelector(state => state.project.selectedProjectId);
  const { users } = useTypedSelector(state => state.user);
  const enabledFeaturesForUser = useTypedSelector(state => state.enabledFeatures.forUser);
  const enabledFeaturesForOrg = useTypedSelector(state => state.enabledFeatures.forOrg);
  const streamYardIntegrationEnabled = useFeatureGateEnabled(ClientFeatures.StreamYardIntegration);
  const [orgPlanSetting = {}] = useSubscriptionPlanSettings('anystring');

  // refresh these org level APIs when org changes.
  // This is to handle cases like:
  // 1. Switch to another org
  // 2. Create and navigate to a new org
  const orgId = me?.orgId;

  useOnVisibilityChange();
  useEffect(() => {
    refreshInvoiceHistory({ keys: 'refresh-all', swr: true });
    refreshBillingDetails({ keys: 'refresh-all', swr: true });
    refreshApiKeyListApi({ keys: 'refresh-all', swr: true });
    refreshApiKey({ keys: 'refresh-all', swr: true });
    refreshSubscriptionPlanSettings({ keys: 'refresh-all' });
  }, [orgId]);

  useEffect(() => {
    if (isAuthenticated || isOrgUserAuthenticated) {
      dispatch(fetchOrgUserProfile());
      dispatch(fetchEnabledFeaturesForUser());
      dispatch(fetchUsers());
    }
  }, [dispatch, isAuthenticated, isOrgUserAuthenticated]);

  useEffect(() => {
    if (me?.orgId) {
      dispatch(fetchEnabledFeaturesForOrg(me?.orgId));
    }
  }, [dispatch, me?.orgId]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // disable SSE for standalone
  const enableSSE =
    !!me?.lndTier && !['stl-dev', 'snw-dev', 'snw-staging', 'snw-production'].includes(me.lndTier);
  useSSESubscription(enableSSE);
  useSSEEventListener(e => {
    const { payload, action } = e.detail;
    if (action === 'reload') {
      queryClient.invalidateQueries(
        datasetQueryKeys.mediaDetails(payload.datasetId, {
          mediaId: payload.mediaId,
          modelId: payload.modelId,
        }),
      );
      refreshLabeledMediaCount({
        projectId: payload.projectId,
        datasetId: payload.datasetId,
      });
      queryClient.invalidateQueries(datasetQueryKeys.modelMetrics(payload.projectId));
    } else if (action === 'profile/refresh') {
      dispatch(fetchOrgUserProfile());
      refreshInvoiceHistory({ keys: 'refresh-all' });
      refreshBillingDetails({ keys: 'refresh-all' });
    } else if (action === 'subscription/refresh') {
      payload.quotaUsage
        ? enqueueSnackbar(
            <Typography>
              {t(
                `Your organization has successfully been upgraded to ${payload.quotaUsage} per month! {{learnMore}}`,
                {
                  learnMore: (
                    <a
                      style={{ fontWeight: 700, cursor: 'pointer' }}
                      onClick={e => {
                        e.preventDefault();
                        closeSnackbar(payload.stripeSubscriptionId);
                        history.push(CLEF_PATH.organizationSettings);
                      }}
                    >
                      Learn More
                    </a>
                  ),
                },
              )}
            </Typography>,
            {
              key: payload.stripeSubscriptionId,
              variant: 'info',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            },
          )
        : enqueueSnackbar(t('Your subscription has been changed'), { variant: 'success' });
    }
  });

  // if there are redirect param in search params, directly go to that page
  const urlSearchParams = new URLSearchParams(location.search);
  const redirectPath = urlSearchParams.get('redirect');

  /**
   * If free trial expires, backend will create a new record, there should be two freemium subscriptions:
   * [
   *   { stripeProductName: 'freemium', status: 'active' },   // freemium
   *   { stripeProductName: 'freemium', status: 'trialing' }, // expired free trial
   * ]
   */
  const showFreeTrialExpiredDialog = useShowFreeTrialExpiredDialog();
  const showFreeTrialWelcomeDialog = useShowFreeTrialWelcomeDialog();

  const enableApiKey = !useFeatureGateEnabled(ClientFeatures.DisableApiKey);
  const enableSubscription = !useFeatureGateEnabled(ClientFeatures.DisableSubscription);
  const enableMemberManagement = !useFeatureGateEnabled(ClientFeatures.DisableMemberManagement);
  const enableInternalSettings = !!useFeatureGateEnabled(ClientFeatures.EnableInternalSettings);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  if (
    !enabledFeaturesForUser ||
    !me ||
    !projects ||
    !users ||
    !enabledFeaturesForOrg ||
    !enabledFeaturesForOrg[me.orgId]
  ) {
    return <AppLoadingSplash />;
  }
  if (!enabledFeaturesForUser.includes(ClientFeatures.SiteSwitch)) {
    return <AppMaintenanceSplash />;
  }

  if (!orgId) {
    return <ErrorPage message="Can't find your organization, please reach out to us for help." />;
  }

  return (
    <>
      {streamYardIntegrationEnabled && <LiveWebinar />}
      {streamYardIntegrationEnabled && <FloatingActionButton />}
      {/* Show confirmation dialog when org is migrated to v2, if not confirmed by customer yet */}
      {!!orgPlanSetting?.pricingV2MigratedAt && !orgPlanSetting?.pricingV2ConfirmedAt && (
        <PricingV2MigrationConfirmationDialog migratedAt={orgPlanSetting.pricingV2MigratedAt} />
      )}
      <PageLayoutRoot>
        <Switch>
          {/* TODO: Might need to consider the quick project creation case support for FRE */}
          <Route
            exact
            path={CLEF_PATH.shortcut.createProject}
            render={({ location }) => {
              return (
                <Redirect
                  to={{
                    ...location,
                    pathname: CLEF_PATH.projects,
                  }}
                />
              );
            }}
          />
          {/* Project level pages */}
          <Route path={`${currentPath}/:orgId/pr/:projectId`} component={AppProjectRouter} />
          {/* Organization level page */}
          {...organizationLevelRoutes(currentPath, {
            enableApiKey,
            enableSubscription,
            enableMemberManagement,
            enableInternalSettings,
          })}
          {/* Fallback, likely legacy pathname, redirect to new routes above */}
          <Route
            render={prop => {
              /**
               * There could be a few reasons why we ended up here
               * 1. The url is '/app' or '/app/:orgId'
               * 2. The url is legacy format, like '/app/data/databrowser` with no orgId and projectId.
               * NOTE: legacy does NOT mean deprecated, most of our code still navigate this way - e.g. history.push(CLEF_PATH.data.dataBrowser)
               */
              const { pathname } = prop.location;
              // since we are here, let's take out currentPath from the pathname
              let pageName = pathname.replace('/app', '');
              // slice out the final '/' in url
              pageName = pageName.endsWith('/') ? pageName.slice(0, pageName.length - 1) : pageName;
              const searchParams = new URLSearchParams(prop.location.search);
              const searchParamProjectId = Number(searchParams.get('pr')) || undefined;
              /**
               * Backwards compatible with legacy routing logic, projectId could exist in 3 different places,
               * use in priority: redux state > search param > local storage
               */
              const cachedProjectId = selectedProjectId ?? searchParamProjectId;
              let finalPathname: string | undefined;

              if (isLegacyOrganizationLevelPath(pageName)) {
                // 1. if this is legacy organization paths, convert it to new org path with orgId in the url.
                finalPathname = `${currentPath}/${orgId}${pageName}`;
              } else if (cachedProjectId) {
                // 2. if there are cached projectId, interpret as project level page and redirect to AppProjectRouter
                // NOTE: this does not guaranteed pageName is valid, invalidation still need to be handled in AppProjectRouter
                finalPathname = `${currentPath}/${orgId}/pr/${cachedProjectId}${pageName}`;
              } else if (pageName === '' || pageName === `/${orgId}`) {
                // 3. fallback, go to projects page or landing page depending on the feature toggle
                // Go to CLEF_PATH.projects
                finalPathname = transformLegacyUrlWithOrgId(CLEF_PATH.home, orgId);
              }

              if (finalPathname) {
                return <Redirect to={{ ...prop.location, pathname: finalPathname }} />;
              } else {
                return <Redirect to={{ ...prop.location, pathname: CLEF_PATH.projects }} />;
              }
            }}
          />
        </Switch>
        {showFreeTrialExpiredDialog && <FreeTrialExpiredDialog />}
        {showFreeTrialWelcomeDialog && <FreeTrialWelcomeDialog />}
      </PageLayoutRoot>
    </>
  );
};

export default AppRouter;
