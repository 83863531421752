import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import classNames from 'classnames';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {
  BatchModelMetricParam,
  DatasetVersion,
  DatasetVersionId,
  LabelType,
  ModelMetricParam,
} from '@clef/shared/types';

import {
  Box,
  Drawer,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { IconButton, Typography, useLocalStorage } from '@clef/client-library';
import { useGetProjectEvaluationSetsListQuery } from '@/serverStore/evaluationSets';
import ModelPerformanceTableCell, {
  MODEL_PERFORMANCE_CELL_MIN_WIDTH,
  SPLITS_CELL_MIN_WIDTH,
} from './TableCell/ModelPerformanceTableCell';
import { EvaluationSetItem } from '@/api/evaluation_set_api';
import { getEvaluationSetName, ModelComparisonSelectionInfo } from './utils';
import ModelDetailsPanel from './ModelDetailsPanel/ModelDetailsPanel';
import {
  isModelTrainingInProgress,
  isModelTrainingSuccessful,
} from '@/store/projectModelInfoState/utils';
import AddEvaluationSetDialog from './AddEvaluationSetDialog';
import {
  RegisteredModelWithBundles,
  RegisteredModelWithBundlesThresholdAndEndpoints,
  RegisteredModelWithThreshold,
  RegisteredModelWithThresholdAndEndpoints,
} from '@/api/model_api';
import { StyledTableCell } from './TableCell/StyledTableCell';
import { AddEvaluationSetEmptyStateTableCell } from './TableCell/AddEvaluationSetEmptyStateTableCell';
import { EvaluationSetTableCell } from './TableCell/EvaluationSetTableCell';
import { AddEvaluationSetBtnTableCell } from './TableCell/AddEvaluationSetBtnTableCell';
import { useHistory } from 'react-router';
import CLEF_PATH from '@/constants/path';
import ModelNameAndConfigurationTableCell from './TableCell/ModelNameAndConfigurationTableCell';
import { MediaSplitName } from '@/constants/stats_card';
import { useDatasetExportedWithVersionsQuery } from '@/serverStore/dataset';
import LoadingProgress from './LoadingProgress';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import OperationTableCell from './TableCell/OperationTableCell';
import DeployTableCell from './TableCell/DeployTableCell';
import { Pagination, Skeleton } from '@material-ui/lab';
import AddEvaluationSetTip from './AddEvaluationSetTip';
import { useHideEvaluationSetMutation } from '@/serverStore/evaluationSets/mutations';
import { useDialog } from '@/components/Layout/components/useDialog';
import { useModels } from '@/hooks/useModels';
import { usePaginatedModels } from './usePaginatedModels';
import { throttle } from 'lodash';
import { batchModelMetricsParamAtom } from './atoms';
import EvaluationSetMoreActions from './EvaluationSetMoreActions';

import {
  MODEL_PERFORMANCE_DETAIL_DRAWER_WIDTH,
  MODEL_PERFORMANCE_DETAIL_DRAWER_EXPANDED_WIDTH,
  MIN_WIDTH_MODEL_NAME_COLUMN,
  STICKY_POSITION_MODEL_NAME_COLUMN,
  FIXED_WIDTH_OPERATIONS_COLUMN,
  STICKY_POSITION_OPERATIONS_COLUMN,
  FIXED_WIDTH_ENDPOINTS_COLUMN,
  STICKY_POSITION_ENDPOINTS_COLUMN,
  FIXED_WIDTH_THRESHOLD_COLUMN,
  FIXED_WIDTH_ADD_EVALUATION_SET_BUTTON_COLUMN,
  FIXED_WIDTH_ADD_EVALUATION_SET_EMPTY_STATE_COLUMN,
  getStickyPositionThresholdColumn,
} from './constants';
import { useGetBatchModelMetricsQueries } from '@/serverStore/modelAnalysis';
import { UseQueryResult } from '@tanstack/react-query';
import { ApiError } from '@/utils/error';
import { BatchModelMetricResponse } from '@/api/model_analysis_api';
import Replay from '@material-ui/icons/Replay';

const getAddEvaluationSetColumnStickyPosition = (
  showThresholdColumn: boolean,
  showDeploymentColumn: boolean,
) => {
  const thresholdColWidth = showThresholdColumn ? FIXED_WIDTH_THRESHOLD_COLUMN : 0;
  const deploymentColWidth = showDeploymentColumn ? FIXED_WIDTH_ENDPOINTS_COLUMN : 0;
  return thresholdColWidth + deploymentColWidth + STICKY_POSITION_ENDPOINTS_COLUMN;
};

/** right sticky: only when drawer open for scrolling */
const getDrawerOpenColumnFixedWidth = (
  showThresholdColumn: boolean,
  showDeploymentColumn: boolean,
  isAddEvalSetEmptyState: boolean,
) => {
  const thresholdColWidth = showThresholdColumn ? FIXED_WIDTH_THRESHOLD_COLUMN : 0;
  const deploymentColWidth = showDeploymentColumn ? FIXED_WIDTH_ENDPOINTS_COLUMN : 0;
  const addEvalSetColWidth = isAddEvalSetEmptyState
    ? FIXED_WIDTH_ADD_EVALUATION_SET_EMPTY_STATE_COLUMN
    : FIXED_WIDTH_ADD_EVALUATION_SET_BUTTON_COLUMN;
  const TABLE_RIGHT_WHITE_SPACE = 24;
  return `calc(${MODEL_PERFORMANCE_DETAIL_DRAWER_WIDTH} - ${thresholdColWidth}px - ${deploymentColWidth}px - ${FIXED_WIDTH_OPERATIONS_COLUMN}px - ${addEvalSetColWidth}px - ${TABLE_RIGHT_WHITE_SPACE}px)`;
};

const getDrawerOpenColumnStickyPosition = (
  showThresholdColumn: boolean,
  showDeploymentColumn: boolean,
  isAddEvalSetEmptyState: boolean,
) => {
  const addEvalSetColWidth = isAddEvalSetEmptyState
    ? FIXED_WIDTH_ADD_EVALUATION_SET_EMPTY_STATE_COLUMN
    : FIXED_WIDTH_ADD_EVALUATION_SET_BUTTON_COLUMN;
  const addEvalSetStickyPosition = getAddEvaluationSetColumnStickyPosition(
    showThresholdColumn,
    showDeploymentColumn,
  );
  return `calc(${addEvalSetStickyPosition}px + ${addEvalSetColWidth}px)`;
};

const TRAIN_SPLIT_TAG_ID = 'trainSplit';
const DEV_SPLIT_TAG_ID = 'devSplit';
const TEST_SPLIT_TAG_ID = 'testSplit';

export const THRESHOLD_UNDEFINED = -1;

const useStyles = makeStyles(theme => ({
  tableContainer: {
    width: 'calc(100% - 70px)',
    border: `1px solid ${theme.palette.greyModern[300]}`,
    boxShadow: 'none !important',
  },
  table: {
    height: 1,
    minWidth: 400,
    borderCollapse: 'separate',
  },
  tableHeaderText: {
    paddingRight: theme.spacing(3),
    fontWeight: 'bold',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  addEvaluationSetCellContainer: {
    height: '100%',
  },
  addEvaluationSetBtnContainer: {
    borderLeftColor: theme.palette.greyModern[300],
    borderLeftStyle: 'solid',
    borderLeftWidth: 0.5,
    position: 'relative',
    right: -theme.spacing(3),
  },
  addEvaluationSetBtn: {
    height: theme.spacing(12),
    width: theme.spacing(12),
  },
  addEvaluationSetBtnIcon: {
    color: theme.palette.indigoBlue[900],
  },
  iconWithGreyModernColor: {
    color: theme.palette.greyModern[500],
    fontSize: 20,
  },
  marginLeft1: {
    marginLeft: theme.spacing(1),
  },
  operationIconBtn: {
    height: 28,
    width: 28,
  },
  operationIcon: {
    height: 18,
    width: 18,
  },
  fullTableCell: {
    height: '100%',
    width: '100%',
    padding: theme.spacing(4),
    // display:'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    // flexDirection: 'row',
  },
  splitColumns: {
    width: '94px',
    minWidth: '94px',
    maxWidth: '94px',
    borderLeft: 'none',
    borderRight: 'none',
  },
  evaluationColumns: {
    maxWidth: '230px',
    padding: theme.spacing(0),
  },
  addEvaluationSetBtnTableCell: {
    margin: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '90%',
    width: '82%',
    borderRadius: '5px',
    color: theme.palette.blue[900],
    backgroundColor: theme.palette.greyModern[50],
    cursor: 'pointer',

    '& p': {
      color: theme.palette.blue[900],
    },
  },
  rightShadow: {
    boxShadow: '4px 2px 6px 0px rgba(18, 25, 38, 0.1)',
  },
  leftShadow: {
    boxShadow: '-4px 2px 6px 0px rgba(18, 25, 38, 0.1)',
  },
  scrollableTable: {
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      boxShadow: '0 0 1px rgba(255, 255, 255, 0.2)',
    },
    '& scrollbar-color': 'rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0)', // Firefox
    '& scrollbar-width': 'thin', // Firefox
  },
  forbidScrollWhileComparing: {
    overflowX: 'hidden',
  },
  reportDrawer: {
    top: '108px', // 108px is the height of the top tabs
    height: 'calc(100% - 108px)',
  },
  testBackdrop: {
    backgroundColor: 'transparent',
  },
}));

const StyledTableRow = withStyles(_theme =>
  createStyles({
    root: {
      //   '&:nth-of-type(odd)': {
      //     backgroundColor: theme.palette.action.hover,
      //   },
    },
  }),
)(TableRow);

interface Column<T> {
  id: string;
  title: string | React.ReactNode;
  render: (param: T) => any;
}

const getModelNameColumns = (
  _classes: any,
  onCellClick: (model: RegisteredModelWithBundles, defaultThreshold: number) => void,
  showScrollAreaShadows?: boolean,
): Column<{
  model: RegisteredModelWithBundles;
  datasetVersionName: string;
  isSelected?: boolean;
}>[] => {
  return [
    {
      id: 'modelName',
      title: (
        <>
          <Typography variant="body_bold">{t('Model')}</Typography>
        </>
      ),
      render: item => {
        return (
          <ModelNameAndConfigurationTableCell
            key={item.model.id}
            rowSpan={item.model.bundles.length || 1}
            className={showScrollAreaShadows ? _classes.rightShadow : ''}
            stickyLeft={true}
            fixedWidth={MIN_WIDTH_MODEL_NAME_COLUMN}
            stickyPosition={STICKY_POSITION_MODEL_NAME_COLUMN}
            model={item.model}
            selected={item.isSelected}
            datasetVersionName={item.datasetVersionName}
            onCellClick={() => {
              onCellClick(
                item.model,
                item.model.bundles.length ? item.model.bundles[0].threshold : 0,
              );
            }}
          />
        );
      },
    },
  ];
};

const getTrainDevTestSetColumns = (
  _classes: any,
  enableComparisons: boolean,
  onCellClick?: (
    model: RegisteredModelWithBundlesThresholdAndEndpoints,
    set: EvaluationSetItem | null,
    splitTagId: string,
  ) => void,
  datasetVersionSplitToEvaluationSet?: Record<number, Record<string, EvaluationSetItem>>,
): Column<{
  modelItem: RegisteredModelWithBundlesThresholdAndEndpoints;
  isSelected?: boolean;
  isSplits?: boolean;
}>[] => {
  return [
    {
      id: TRAIN_SPLIT_TAG_ID,
      tooltipKey: 'trainSet',
      title: t('Train'),
      splitName: MediaSplitName.Train,
    },
    {
      id: DEV_SPLIT_TAG_ID,
      tooltipKey: 'devSet',
      title: t('Dev'),
      splitName: MediaSplitName.Dev,
    },
    {
      id: TEST_SPLIT_TAG_ID,
      tooltipKey: 'testSet',
      title: t('Test'),
      splitName: MediaSplitName.Test,
    },
  ].map(tableItem => {
    return {
      id: tableItem.id,
      title: (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          marginTop={1}
        >
          <Typography variant="body_bold">{tableItem.title}</Typography>
          {/* TODO: designer may want to add it back */}
          {/* <Tooltip arrow placement="top" title={tooltips[tableItem.tooltipKey]}>
            <HelpOutlineIcon
              className={cx(_classes.iconWithGreyModernColor, _classes.marginLeft1)}
            />
          </Tooltip> */}
        </Box>
      ),
      render: item => {
        const { modelItem, isSelected, isSplits } = item;
        const evaluationSet =
          datasetVersionSplitToEvaluationSet?.[modelItem.datasetVersionId!]?.[tableItem.splitName];

        return (
          <ModelPerformanceTableCell
            key={`${modelItem.id}-${modelItem.threshold}-${tableItem.id}`}
            model={modelItem}
            threshold={modelItem.threshold}
            evaluationSet={evaluationSet!}
            onCellClick={() => onCellClick?.(modelItem, evaluationSet ?? null, tableItem.id)}
            enableComparison={enableComparisons}
            disabled={enableComparisons}
            selected={isSelected}
            splitName={tableItem.splitName}
            isSplits={isSplits}
          />
        );
      },
    };
  });
};

const getModelThresholdAndEndpointsColumns = (
  _classes: any,
  enableComparisons: boolean,
  showThresholdColumn: boolean,
  showDeploymentColumn: boolean,
  onEndpointIconClick: (id: string) => void,
  queries: UseQueryResult<BatchModelMetricResponse, ApiError>[],
  batchModelMetricsParam: BatchModelMetricParam,
  showScrollAreaShadows?: boolean,
): Column<RegisteredModelWithThresholdAndEndpoints & { bundleId: string | null }>[] => {
  return [
    ...(showThresholdColumn
      ? [
          {
            id: 'threshold',
            title: (
              <StyledTableCell
                key={'threshold'}
                disabled={enableComparisons}
                className={showScrollAreaShadows ? _classes.leftShadow : ''}
                stickyRight={true}
                fixedWidth={FIXED_WIDTH_THRESHOLD_COLUMN}
                stickyPosition={getStickyPositionThresholdColumn(showDeploymentColumn)}
              >
                <Typography variant="body_bold">{t('Confidence threshold')}</Typography>
              </StyledTableCell>
            ),
            render: item => {
              const index = batchModelMetricsParam.findIndex(params =>
                params.some(
                  param => param.modelId === item.id && param.threshold === item.threshold,
                ),
              );
              const { error, refetch, isFetching } = queries[index] ?? {};
              return (
                <StyledTableCell
                  key={`threshold-${item.id}-${item.threshold}`}
                  disabled={enableComparisons}
                  className={showScrollAreaShadows ? _classes.leftShadow : ''}
                  stickyRight={true}
                  fixedWidth={FIXED_WIDTH_THRESHOLD_COLUMN}
                  stickyPosition={getStickyPositionThresholdColumn(showDeploymentColumn)}
                >
                  <Box className={_classes.fullTableCell}>
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      {item.threshold === THRESHOLD_UNDEFINED ? (
                        <Tooltip title={t('Model metrics not ready')}>
                          <Typography>{'--'}</Typography>
                        </Tooltip>
                      ) : isModelTrainingSuccessful(item.status, item.metricsReady) ? (
                        <>
                          {item.threshold}
                          {error && !isFetching && (
                            <IconButton
                              size={'small'}
                              tooltip="Re-fetch metrics for this threshold"
                              onClick={() => refetch?.()}
                            >
                              <Replay />
                            </IconButton>
                          )}
                        </>
                      ) : (
                        <Tooltip title={t('Model metrics not ready')}>
                          <Typography>{'--'}</Typography>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                </StyledTableCell>
              );
            },
          } as Column<RegisteredModelWithThresholdAndEndpoints>,
        ]
      : []),
    ...(showDeploymentColumn
      ? [
          {
            id: 'endpoint',
            title: (
              <StyledTableCell
                key={'endpoint'}
                disabled={enableComparisons}
                align="left"
                stickyRight={true}
                fixedWidth={FIXED_WIDTH_ENDPOINTS_COLUMN}
                stickyPosition={STICKY_POSITION_ENDPOINTS_COLUMN}
              >
                <Typography variant="body_bold">{t('Deployment')}</Typography>
              </StyledTableCell>
            ),
            render: item => {
              return (
                <DeployTableCell
                  key={`endpoint-${item.id}-${item.threshold}-${item.bundleId}`}
                  model={item}
                  threshold={item.threshold}
                  bundleId={item.bundleId}
                  onEndpointIconClick={onEndpointIconClick}
                />
              );
            },
          } as Column<RegisteredModelWithThresholdAndEndpoints & { bundleId: string | null }>,
        ]
      : []),
  ];
};

type EvaluationSetCellData = RegisteredModelWithBundlesThresholdAndEndpoints;
const getEvaluationSetColumns = (
  classes: any,
  evaluationSets: EvaluationSetItem[],
  enableComparison: boolean,
  comparisonSelectionInfo: ModelComparisonSelectionInfo,
  handleArchiveEvaluationSetClick: (evaluationSetId: number) => void,
  onCompareModelsClick?: (evaluationSet: EvaluationSetItem) => void,
  onCellClick?: (model: EvaluationSetCellData, set: EvaluationSetItem) => void,
  onCompareClick?: (model: EvaluationSetCellData, set: EvaluationSetItem) => void,
): Column<{
  modelItem: EvaluationSetCellData;
  isSelected?: boolean;
  showOnboardingHighlight?: boolean;
}>[] => {
  const getColumn = (
    set: EvaluationSetItem,
    index: number,
  ): Column<{
    modelItem: EvaluationSetCellData;
    isSelected?: boolean;
    showOnboardingHighlight?: boolean;
  }> => {
    const { evaluationSet: selectedEvaluationSet } = comparisonSelectionInfo;
    const highlight =
      enableComparison && selectedEvaluationSet && set.id === selectedEvaluationSet.id;
    const disabled =
      enableComparison && selectedEvaluationSet && set.id !== selectedEvaluationSet.id;
    return {
      id: `eval-${set.id}`,
      title: (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
          <Tooltip arrow={true} title={set ? getEvaluationSetName(set) : ''} placement={'top'}>
            <Box mt={4} mb={4} ml={4}>
              <Typography className={classes.tableHeaderText}>
                {set ? getEvaluationSetName(set) : ''}
              </Typography>
            </Box>
          </Tooltip>
          <EvaluationSetMoreActions
            snapshotVersion={set.datasetVersion.version}
            onCompareModelsClick={() => {
              onCompareModelsClick?.(set);
            }}
            onArchiveEvaluationSetClick={() => {
              handleArchiveEvaluationSetClick(set.id);
            }}
            disabled={enableComparison}
          />
        </Box>
      ),
      render: item => {
        const { modelItem, isSelected, showOnboardingHighlight } = item;
        return (
          <ModelPerformanceTableCell
            key={`${modelItem.id}-${modelItem.threshold}-${index}`}
            model={modelItem}
            threshold={modelItem.threshold}
            evaluationSet={set}
            comparisonSelectionInfo={comparisonSelectionInfo}
            enableComparison={enableComparison}
            onCellClick={() => onCellClick?.(modelItem, set)}
            onCompareClick={() => onCompareClick?.(modelItem, set)}
            columnSelected={highlight} // hand it to ModelPerformanceTableCell to control the highlighting
            onBoardingHighlight={showOnboardingHighlight} // hand it to ModelPerformanceTableCell to control the highlighting
            disabled={disabled}
            selected={isSelected}
            className={classes.evaluationColumns}
          />
        );
      },
    };
  };
  return evaluationSets.map((set, index) => getColumn(set, index));
};

const getAddEvaluationSetBtnColumn = (
  classes: any,
  handleAddClick: () => void,
  disableAddAction?: boolean,
) => {
  return {
    id: `add-evaluation-set-btn`,
    title: (
      <Box display="flex" justifyContent="center">
        {/* TODO: designer may want to add it back */}
        {/* <IconButton
          id="add-evaluation-set-button"
          className={classes.addEvaluationSetBtn}
          onClick={handleAddClick}
          tooltip={tooltips['addEvaluationSetBtn']}
        >
          <AddRoundedIcon className={classes.addEvaluationSetBtnIcon} />
        </IconButton> */}
        <Typography variant="body_bold">{t('More evaluation set')}</Typography>
      </Box>
    ),
    render: (rowSpan: number) => (
      <AddEvaluationSetBtnTableCell
        rowSpan={rowSpan}
        stickyRight={true}
        fixedWidth={FIXED_WIDTH_ADD_EVALUATION_SET_BUTTON_COLUMN}
        disabled={disableAddAction}
      >
        <Box
          className={classes.addEvaluationSetBtnTableCell}
          onClick={handleAddClick}
          fontSize="small"
        >
          <AddIcon />
          <Typography variant="body_bold">{t('Add')}</Typography>
        </Box>
      </AddEvaluationSetBtnTableCell>
    ),
  };
};

const getDrawerOpenColumn = (
  showThresholdColumn: boolean,
  showDeploymentColumn: boolean,
  isAddEvalSetEmptyState: boolean,
  enableComparison: boolean,
) => {
  const drawerOpenColWidth = getDrawerOpenColumnFixedWidth(
    showThresholdColumn,
    showDeploymentColumn,
    isAddEvalSetEmptyState,
  );
  const drawerStickyPosition = getDrawerOpenColumnStickyPosition(
    showThresholdColumn,
    showDeploymentColumn,
    isAddEvalSetEmptyState,
  );
  return {
    id: `add-evaluation-set-btn`,
    title: (
      <StyledTableCell
        key={'drawer-open'}
        disabled={enableComparison}
        align="left"
        stickyRight={true}
        fixedWidth={drawerOpenColWidth}
        stickyPosition={drawerStickyPosition}
      />
    ),
    render: () => (
      <StyledTableCell
        stickyRight={true}
        fixedWidth={drawerOpenColWidth}
        stickyPosition={drawerStickyPosition}
      />
    ),
  };
};

const getOperationColumns = (
  _classes: any,
): Column<RegisteredModelWithThreshold & { isFav: boolean | null; bundleId: string | null }>[] => {
  return [
    {
      id: 'operations',
      title: <></>,
      render: item => {
        return (
          <OperationTableCell
            model={item}
            threshold={item.threshold}
            bundleId={item.bundleId}
            isFav={item.isFav}
          />
        );
      },
    },
  ];
};

const ModelPerformanceTableCellWithoutThreshold = (props: {
  cellKey: string;
  enableComparison: boolean;
  classes: any;
}) => {
  const { cellKey, enableComparison, classes } = props;
  return (
    <StyledTableCell key={cellKey} disabled={enableComparison} align="right">
      <Tooltip title="this model has no confidence threshold">
        <Box
          className={classes.fullTableCell}
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography>{t('--')}</Typography>
        </Box>
      </Tooltip>
    </StyledTableCell>
  );
};

export type ModelPerformanceTableProps = {
  savedModels: RegisteredModelWithBundles[];
  enableComparison?: boolean;
  onCompareModelsClick?: (evaluationSet: EvaluationSetItem) => void;
  comparisonSelectionInfo: ModelComparisonSelectionInfo;
  handleComparisonClick?: (model: EvaluationSetCellData, evaluationSet: EvaluationSetItem) => void;
};

const ModelPerformanceTable: React.FC<ModelPerformanceTableProps> = props => {
  const {
    enableComparison = false,
    onCompareModelsClick,
    comparisonSelectionInfo,
    handleComparisonClick,
    savedModels,
  } = props;
  const classes = useStyles();

  const { page, totalPages, handlePaginationChange, paginatedModels } =
    usePaginatedModels(savedModels);

  const { data: evaluationSets, isLoading: isEvaluationSetsLoading } =
    useGetProjectEvaluationSetsListQuery();

  const { data: snapshots } = useDatasetExportedWithVersionsQuery({
    withCount: true,
    includeNotCompleted: true,
    includeFastEasy: true,
  });
  const [
    skipFirstTimeAddEvaluationSetEmptyStateTip,
    setSkipFirstTimeAddEvaluationSetEmptyStateTip,
  ] = useLocalStorage<boolean>('skipFirstTimeAddEvaluationSetEmptyStateTip');

  const tableRef = useRef<null | HTMLElement>(null);
  const tableInnerRef = useRef<null | HTMLTableElement>(null);
  const showScrollAreaShadows =
    tableRef.current && tableInnerRef.current
      ? tableRef.current.clientWidth + 2 < tableInnerRef.current.clientWidth // add 2 pixels to avoid the error by border
      : false;
  const [scrollToLeftEnd, setScrollToLeftEnd] = useState(true);
  const [scrollToRightEnd, setScrollToRightEnd] = useState(false);

  useEffect(() => {
    if (isEvaluationSetsLoading) {
      return;
    }
    const currentElement = tableRef.current;
    const handleScroll = throttle(() => {
      setScrollToLeftEnd(tableRef.current?.scrollLeft === 0);
      setScrollToRightEnd(
        (tableRef.current?.scrollLeft ?? 0) + (tableRef.current?.clientWidth ?? 0) ===
          tableRef.current?.scrollWidth,
      );
    }, 100);
    if (currentElement) {
      currentElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      handleScroll.cancel(); // use lodash's throttle.cancel() to cancel the throttled function
      currentElement?.removeEventListener('scroll', handleScroll);
    };
  }, [isEvaluationSetsLoading]);

  const datasetVersions = snapshots && snapshots.datasetVersions ? snapshots.datasetVersions : [];
  const datasetVersionsMap = datasetVersions.reduce((acc, cur: DatasetVersion) => {
    return { ...acc, [cur.id]: cur };
  }, {} as Record<DatasetVersionId, DatasetVersion>);

  const [addEvaluationSetDialogOpen, setAddEvaluationSetDialogOpen] = useState(false);
  const { showConfirmationDialog } = useDialog();
  const history = useHistory();
  const hideEvaluationSet = useHideEvaluationSetMutation();
  const { findModels } = useModels();

  const handleAddClick = () => {
    setAddEvaluationSetDialogOpen(true);
  };
  const handleGotItClick = () => {
    setSkipFirstTimeAddEvaluationSetEmptyStateTip(true);
  };

  const [trainDevTestColumnEvaluationSets, otherEvaluationSets, unhiddenEvaluationSets] =
    useMemo(() => {
      const modelDatasetVersionIds = new Set(paginatedModels?.map(model => model.datasetVersionId));
      // those shown as dev column and test column
      const trainDevTestColumnEvaluationSets: EvaluationSetItem[] = [];
      // those shown as additional columns
      const otherEvaluationSets: EvaluationSetItem[] = [];
      (evaluationSets ?? []).forEach(evaluationSet => {
        if (
          evaluationSet.split?.splitSetName &&
          [MediaSplitName.Train, MediaSplitName.Dev, MediaSplitName.Test].includes(
            evaluationSet.split?.splitSetName as MediaSplitName,
          ) &&
          modelDatasetVersionIds.has(evaluationSet.datasetVersionId)
        ) {
          trainDevTestColumnEvaluationSets.push(evaluationSet);
        } else {
          otherEvaluationSets.push(evaluationSet);
        }
      });
      const unhiddenEvaluationSets: EvaluationSetItem[] = (evaluationSets ?? []).filter(
        set => !set.hidden,
      );
      return [trainDevTestColumnEvaluationSets, otherEvaluationSets, unhiddenEvaluationSets];
    }, [evaluationSets, paginatedModels]);

  const datasetVersionSplitToEvaluationSet = useMemo(() => {
    const reducer = (
      acc: Record<number, Record<string, EvaluationSetItem>>,
      item: EvaluationSetItem,
    ) => {
      acc[item.datasetVersionId] = acc[item.datasetVersionId] || {};
      acc[item.datasetVersionId][item.split?.splitSetName ?? ''] = item;
      return acc;
    };
    return [...trainDevTestColumnEvaluationSets, ...otherEvaluationSets].reduce(
      reducer,
      {} as Record<number, Record<string, EvaluationSetItem>>,
    );
  }, [trainDevTestColumnEvaluationSets, otherEvaluationSets]);

  const [, setBatchMetricsParam] = useAtom(batchModelMetricsParamAtom);

  const modelIdFromSearch = new URLSearchParams(history?.location?.search).get('modelId');

  const modelFromSearch = savedModels.find(model => model.id === modelIdFromSearch);
  const modelThresholdFromSearch = modelFromSearch?.bundles.length
    ? modelFromSearch?.bundles[0].threshold
    : null;

  useEffect(() => {
    const params: BatchModelMetricParam = [];
    paginatedModels.forEach(({ bundles, id, datasetVersionId }) => {
      if (!datasetVersionId) return;
      bundles.forEach(bundle => {
        const bundleParams: ModelMetricParam[] = [];
        [MediaSplitName.Train, MediaSplitName.Dev, MediaSplitName.Test].forEach(splitName => {
          const evaluationSet = datasetVersionSplitToEvaluationSet[datasetVersionId]?.[splitName];
          evaluationSet &&
            bundleParams.push({
              modelId: id,
              evaluationSetId: evaluationSet.id,
              threshold: bundle.threshold,
            });
        });
        unhiddenEvaluationSets.forEach(value => {
          bundleParams.push({
            modelId: id,
            evaluationSetId: value.id,
            threshold: bundle.threshold,
          });
        });
        bundleParams.sort((a, b) => {
          if (a.modelId === b.modelId) {
            return a.evaluationSetId !== b.evaluationSetId
              ? a.evaluationSetId - b.evaluationSetId
              : a.threshold - b.threshold;
          } else {
            return a.modelId.localeCompare(b.modelId);
          }
        });
        params.push(bundleParams);
      });
    });
    setBatchMetricsParam(params);
  }, [
    paginatedModels,
    datasetVersionSplitToEvaluationSet,
    unhiddenEvaluationSets,
    setBatchMetricsParam,
  ]);

  const [selectedCellData, setSelectedCellData] = useState<
    | {
        model: RegisteredModelWithBundles;
        evaluationSet: EvaluationSetItem | undefined;
        threshold: number;
        splitTagId: string | undefined; // to distinguish from train/dev/test col cell or other eval set col performance cell
      }
    | undefined
  >(
    modelIdFromSearch && modelFromSearch && modelThresholdFromSearch
      ? {
          model: modelFromSearch,
          threshold: modelThresholdFromSearch,
          splitTagId: undefined,
          evaluationSet: undefined,
        }
      : undefined,
  );
  const location = useLocation();

  const initialHighlightModelIdFromSearch = new URLSearchParams(history?.location?.search).get(
    'highlight',
  );
  const [highlightedModelId, setHighlightedModelId] = useState<string | null>(
    initialHighlightModelIdFromSearch,
  ); // only used for highlighting the model name cell when redirecting from build page deploy dialog v2 generate downloadable bundle
  useEffect(() => {
    if (highlightedModelId && selectedCellData) {
      setHighlightedModelId(null);
      const searchParams = new URLSearchParams(location.search);
      searchParams.delete('highlight');
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [selectedCellData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const initialModelId = searchParams.get('modelId');
    const initialThreshold = searchParams.get('threshold');
    if (initialModelId && initialThreshold) {
      setSelectedCellData({
        model: savedModels.find(model => model.id === initialModelId)!,
        evaluationSet: undefined,
        threshold: parseFloat(initialThreshold),
        splitTagId: undefined,
      });
      searchParams.delete('modelId');
      searchParams.delete('threshold');
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [history, location.pathname, savedModels, location.search]);

  const onEvaluationCellClick = useCallback(
    (model: EvaluationSetCellData, set: EvaluationSetItem | null, splitTagId?: string) => {
      const { threshold } = model ?? {};
      const executeScroll = (set: EvaluationSetItem, splitTagId?: string) => {
        let xScrollOffsetIndex = 0;
        if (splitTagId) {
          xScrollOffsetIndex = [TRAIN_SPLIT_TAG_ID, DEV_SPLIT_TAG_ID, TEST_SPLIT_TAG_ID].findIndex(
            tagId => {
              return splitTagId === tagId;
            },
          );
          setTimeout(() => {
            const ADJUST_WIDTH = 0;
            tableRef?.current?.scrollTo({
              left: xScrollOffsetIndex * SPLITS_CELL_MIN_WIDTH + ADJUST_WIDTH,
              behavior: 'smooth',
            });
          }, 0);
        } else {
          xScrollOffsetIndex =
            [TRAIN_SPLIT_TAG_ID, DEV_SPLIT_TAG_ID, TEST_SPLIT_TAG_ID].length +
            unhiddenEvaluationSets.findIndex(setItem => {
              return setItem.id === set.id;
            });
          setTimeout(() => {
            const ADJUST_WIDTH = 138;
            const splitSetsLength = [TRAIN_SPLIT_TAG_ID, DEV_SPLIT_TAG_ID, TEST_SPLIT_TAG_ID]
              .length;
            tableRef?.current?.scrollTo({
              left:
                splitSetsLength * SPLITS_CELL_MIN_WIDTH +
                (xScrollOffsetIndex - splitSetsLength) * MODEL_PERFORMANCE_CELL_MIN_WIDTH +
                ADJUST_WIDTH,
              behavior: 'smooth',
            });
          }, 0);
        }
      };
      if (!set) {
        return;
      }
      if (enableComparison) {
        handleComparisonClick?.(model, set);
      } else {
        setSelectedCellData({
          model,
          evaluationSet: set,
          threshold: threshold,
          splitTagId: splitTagId,
        });
        executeScroll(set, splitTagId);
      }
    },
    [datasetVersionSplitToEvaluationSet, enableComparison, handleComparisonClick],
  );

  const onModelNameCellClick = useCallback(
    (model: RegisteredModelWithBundles, defaultThreshold: number) => {
      setSelectedCellData({
        model,
        evaluationSet: undefined,
        threshold: defaultThreshold,
        splitTagId: undefined,
      });
    },
    [],
  );

  const handleArchiveEvaluationSetClick = (evaluationSetId: number) => {
    showConfirmationDialog({
      title: t('Are you sure you want to archive this evaluation set?'),
      content: t(
        'Archived evaluation sets and their corresponding performance reports will be hidden from the model table. You can always re-add these sets later. Do you want to continue?',
      ),
      confirmText: t('Yes'),
      color: 'secondary',
      onConfirm: async () => {
        hideEvaluationSet.mutate({ evaluationSetId });
      },
    });
  };

  const modelNameColumns = getModelNameColumns(
    classes,
    onModelNameCellClick,
    showScrollAreaShadows && !scrollToLeftEnd,
  );
  const { data: project } = useGetSelectedProjectQuery();
  const { labelType } = project ?? {};
  const showThresholdColumn = labelType !== LabelType.Classification;
  const modelMetricQueries = useGetBatchModelMetricsQueries();
  const [batchModelMetricsParam] = useAtom(batchModelMetricsParamAtom);
  const modelThresholdAndEndpointsColumns = getModelThresholdAndEndpointsColumns(
    classes,
    enableComparison,
    showThresholdColumn,
    true, //isCloudDeploymentEnabled is always true
    (endpointId: string) => {
      history.push(`${CLEF_PATH.deployment.overview}?selected_endpoint_id=${endpointId}`);
    },
    modelMetricQueries,
    batchModelMetricsParam,
    showScrollAreaShadows && !scrollToRightEnd,
  );
  const trainDevTestSetColumns = getTrainDevTestSetColumns(
    classes,
    enableComparison,
    onEvaluationCellClick,
    datasetVersionSplitToEvaluationSet,
  );

  const evaluationSetColumns = getEvaluationSetColumns(
    classes,
    unhiddenEvaluationSets,
    enableComparison,
    comparisonSelectionInfo,
    handleArchiveEvaluationSetClick,
    onCompareModelsClick,
    onEvaluationCellClick,
    handleComparisonClick,
  );
  const displayAddEvalSetOnboardingMessage = evaluationSetColumns.length === 1;
  const showAddEvaluationSetEmptyState =
    !skipFirstTimeAddEvaluationSetEmptyStateTip && evaluationSetColumns.length === 0;
  const addEvaluationSetBtnColumn = getAddEvaluationSetBtnColumn(
    classes,
    handleAddClick,
    enableComparison,
  );

  const drawerOpenColumn = getDrawerOpenColumn(
    showThresholdColumn,
    true, //isCloudDeploymentEnabled is always true
    showAddEvaluationSetEmptyState,
    enableComparison,
  );
  const operationColumns = getOperationColumns(classes);

  const AddEvaluationSetTableCellRowSpan = savedModels.reduce((prev, curr) => {
    if (curr.bundles.length === 0) return prev + 1;
    return prev + curr.bundles.length;
  }, 0);

  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);

  if (isEvaluationSetsLoading) return <LoadingProgress />;

  return (
    <>
      <TableContainer
        ref={tableRef}
        classes={{ root: classes.tableContainer }}
        component={Paper}
        className={classNames({
          [classes.scrollableTable]: showScrollAreaShadows,
          [classes.forbidScrollWhileComparing]: enableComparison,
        })}
      >
        <Table ref={tableInnerRef} className={classes.table} aria-label="model-bundle-list-table">
          <TableHead>
            <TableRow>
              {modelNameColumns.map(column => (
                <StyledTableCell
                  key={column.id}
                  disabled={enableComparison}
                  stickyLeft={true}
                  fixedWidth={MIN_WIDTH_MODEL_NAME_COLUMN}
                  stickyPosition={STICKY_POSITION_MODEL_NAME_COLUMN}
                  className={showScrollAreaShadows && !scrollToLeftEnd ? classes.rightShadow : ''}
                >
                  {column.title}
                </StyledTableCell>
              ))}
              {trainDevTestSetColumns.map(column => (
                <StyledTableCell
                  key={column.id}
                  disabled={enableComparison}
                  className={classes.splitColumns}
                >
                  {column.title}
                </StyledTableCell>
              ))}
              {evaluationSetColumns.map(column => (
                <EvaluationSetTableCell
                  key={column.id}
                  id={column.id + '-header'}
                  align="right"
                  className={classes.evaluationColumns}
                >
                  {column.title}
                </EvaluationSetTableCell>
              ))}
              {!!selectedCellData && drawerOpenColumn.title}
              {showAddEvaluationSetEmptyState ? (
                <AddEvaluationSetEmptyStateTableCell
                  key={`add-evaluation-set-header`}
                  stickyRight={true}
                  fixedWidth={FIXED_WIDTH_ADD_EVALUATION_SET_EMPTY_STATE_COLUMN}
                  stickyPosition={getAddEvaluationSetColumnStickyPosition(
                    showThresholdColumn,
                    true, //isCloudDeploymentEnabled is always true
                  )}
                />
              ) : (
                <AddEvaluationSetBtnTableCell
                  key={addEvaluationSetBtnColumn.id}
                  stickyRight={true}
                  fixedWidth={FIXED_WIDTH_ADD_EVALUATION_SET_BUTTON_COLUMN}
                >
                  {addEvaluationSetBtnColumn.title}
                </AddEvaluationSetBtnTableCell>
              )}
              {modelThresholdAndEndpointsColumns.map(column => column.title)}
              {operationColumns.map(column => (
                <StyledTableCell
                  key={column.id}
                  stickyRight={true}
                  fixedWidth={FIXED_WIDTH_OPERATIONS_COLUMN}
                  stickyPosition={STICKY_POSITION_OPERATIONS_COLUMN}
                >
                  {column.title}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedModels.map((projectModel, modelIndex) => {
              // models with 0 bundles are either in progress model or old models
              if (projectModel.bundles.length === 0) {
                const isInProgressModel = isModelTrainingInProgress(
                  projectModel.status,
                  projectModel.metricsReady,
                );
                const currThreshold = findModels(projectModel.id)?.confidence;
                const getTableElementKey = (
                  isInProgressModel: boolean,
                  projectModelId: string,
                  columnId: string | null,
                ) => {
                  return isInProgressModel
                    ? `${projectModelId}-in-progress-${columnId}`
                    : `${projectModelId}-old-models-${columnId}`;
                };
                return [
                  <StyledTableRow
                    key={getTableElementKey(isInProgressModel, projectModel.id, null)}
                    onClick={() => {}}
                  >
                    {modelNameColumns.map(column => {
                      const isSelected =
                        (projectModel.id === selectedCellData?.model.id &&
                          !selectedCellData?.evaluationSet) ||
                        (selectedCellData === undefined && highlightedModelId === projectModel.id);
                      return column.render?.({
                        model: projectModel,
                        datasetVersionName: datasetVersionsMap[projectModel.datasetVersionId!]
                          ?.name ?? <Skeleton variant="text" width={50} />,
                        isSelected: isSelected,
                      });
                    })}
                    {trainDevTestSetColumns.map(column => {
                      return currThreshold ? (
                        column.render?.({
                          modelItem: {
                            ...projectModel,
                            threshold: currThreshold,
                            endpoints: [],
                          },
                          isSelected:
                            projectModel.id === selectedCellData?.model.id &&
                            currThreshold === selectedCellData?.threshold &&
                            column.id === selectedCellData.splitTagId,
                          isSplits: true,
                        })
                      ) : (
                        <ModelPerformanceTableCellWithoutThreshold
                          cellKey={getTableElementKey(
                            isInProgressModel,
                            projectModel.id,
                            column.id,
                          )}
                          enableComparison={enableComparison}
                          classes={classes}
                        />
                      );
                    })}
                    {evaluationSetColumns.map(column => {
                      return currThreshold ? (
                        column.render?.({
                          modelItem: {
                            ...projectModel,
                            threshold: currThreshold,
                            endpoints: [],
                          },
                          isSelected:
                            projectModel.id === selectedCellData?.model.id &&
                            currThreshold === selectedCellData?.threshold &&
                            column.id === `eval-${selectedCellData?.evaluationSet?.id}` &&
                            selectedCellData.splitTagId === undefined,
                          showOnboardingHighlight: displayAddEvalSetOnboardingMessage,
                        })
                      ) : (
                        <ModelPerformanceTableCellWithoutThreshold
                          cellKey={getTableElementKey(
                            isInProgressModel,
                            projectModel.id,
                            column.id,
                          )}
                          enableComparison={enableComparison}
                          classes={classes}
                        />
                      );
                    })}
                    {!!selectedCellData && drawerOpenColumn.render()}
                    {showAddEvaluationSetEmptyState && modelIndex === 0 && (
                      <AddEvaluationSetEmptyStateTableCell
                        rowSpan={AddEvaluationSetTableCellRowSpan}
                        key={`add-evaluation-set-body`}
                        stickyRight={true}
                        fixedWidth={FIXED_WIDTH_ADD_EVALUATION_SET_EMPTY_STATE_COLUMN}
                        stickyPosition={getAddEvaluationSetColumnStickyPosition(
                          showThresholdColumn,
                          true, //isCloudDeploymentEnabled is always true
                        )}
                      >
                        <AddEvaluationSetTip
                          handleAddClick={handleAddClick}
                          handleGotItClick={handleGotItClick}
                        />
                      </AddEvaluationSetEmptyStateTableCell>
                    )}
                    {!showAddEvaluationSetEmptyState &&
                      modelIndex === 0 &&
                      addEvaluationSetBtnColumn.render?.(AddEvaluationSetTableCellRowSpan)}
                    {modelThresholdAndEndpointsColumns.map(column =>
                      column.render?.({
                        ...projectModel,
                        threshold: currThreshold ?? THRESHOLD_UNDEFINED,
                        endpoints: [],
                        bundleId: null,
                      }),
                    )}
                    {operationColumns.map(column => {
                      return (
                        <StyledTableCell
                          key={`operation-${projectModel.id}-${currThreshold}-${column.id}`}
                          align="right"
                          disabled={enableComparison}
                          stickyRight={true}
                          fixedWidth={FIXED_WIDTH_OPERATIONS_COLUMN}
                          stickyPosition={STICKY_POSITION_OPERATIONS_COLUMN}
                        >
                          {column.render?.({
                            ...projectModel,
                            threshold: currThreshold ?? THRESHOLD_UNDEFINED,
                            isFav: null,
                            bundleId: null,
                          })}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>,
                ];
              }
              return projectModel.bundles.map((currBundle, bundleIndex: number) => {
                const currThreshold = currBundle.threshold;
                const currEndpoints = currBundle.endpoints;
                return (
                  <StyledTableRow key={`${projectModel.id}-${currThreshold}`} onClick={() => {}}>
                    {bundleIndex === 0 &&
                      modelNameColumns.map(column => {
                        const isSelected =
                          (projectModel.id === selectedCellData?.model.id &&
                            !selectedCellData?.evaluationSet) ||
                          (selectedCellData === undefined &&
                            highlightedModelId === projectModel.id);
                        return column.render?.({
                          model: projectModel,
                          datasetVersionName: datasetVersionsMap[projectModel.datasetVersionId!]
                            ?.name ?? <Skeleton variant="text" width={50} />,
                          isSelected: isSelected,
                        });
                      })}
                    {trainDevTestSetColumns.map(column => {
                      return column.render?.({
                        modelItem: {
                          ...projectModel,
                          threshold: currThreshold,
                          endpoints: currEndpoints,
                        },
                        isSelected:
                          projectModel.id === selectedCellData?.model.id &&
                          currThreshold === selectedCellData?.threshold &&
                          column.id === selectedCellData.splitTagId,
                        isSplits: true,
                      });
                    })}
                    {evaluationSetColumns.map(column =>
                      column.render?.({
                        modelItem: {
                          ...projectModel,
                          threshold: currThreshold,
                          endpoints: currEndpoints,
                        },
                        isSelected:
                          projectModel.id === selectedCellData?.model.id &&
                          currThreshold === selectedCellData?.threshold &&
                          column.id === `eval-${selectedCellData?.evaluationSet?.id}` &&
                          selectedCellData.splitTagId === undefined,
                        showOnboardingHighlight: displayAddEvalSetOnboardingMessage,
                      }),
                    )}
                    {!!selectedCellData && drawerOpenColumn.render()}
                    {showAddEvaluationSetEmptyState && modelIndex === 0 && bundleIndex == 0 && (
                      <AddEvaluationSetEmptyStateTableCell
                        rowSpan={AddEvaluationSetTableCellRowSpan}
                        key={`add-evaluation-set-body`}
                        stickyRight={true}
                        fixedWidth={FIXED_WIDTH_ADD_EVALUATION_SET_EMPTY_STATE_COLUMN}
                        stickyPosition={getAddEvaluationSetColumnStickyPosition(
                          showThresholdColumn,
                          true, //isCloudDeploymentEnabled is always true
                        )}
                      >
                        <AddEvaluationSetTip
                          handleAddClick={handleAddClick}
                          handleGotItClick={handleGotItClick}
                        />
                      </AddEvaluationSetEmptyStateTableCell>
                    )}
                    {!showAddEvaluationSetEmptyState &&
                      modelIndex === 0 &&
                      bundleIndex == 0 &&
                      addEvaluationSetBtnColumn.render?.(AddEvaluationSetTableCellRowSpan)}
                    {modelThresholdAndEndpointsColumns.map(column =>
                      column.render?.({
                        ...projectModel,
                        threshold: currThreshold,
                        endpoints: currEndpoints,
                        bundleId: currBundle.id,
                      }),
                    )}
                    {operationColumns.map(column => {
                      return (
                        <StyledTableCell
                          key={`operation-${projectModel.id}-${currThreshold}-${column.id}`}
                          align="right"
                          disabled={enableComparison}
                          stickyRight={true}
                          fixedWidth={FIXED_WIDTH_OPERATIONS_COLUMN}
                          stickyPosition={STICKY_POSITION_OPERATIONS_COLUMN}
                        >
                          {column.render?.({
                            ...projectModel,
                            threshold: currThreshold,
                            isFav: currBundle.isFav ?? false,
                            bundleId: currBundle.id,
                          })}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        display="flex"
        width="calc(100% - 70px)"
        flexDirection="row"
        justifyContent="flex-end"
        marginTop={5}
      >
        <Pagination count={totalPages} page={page} onChange={handlePaginationChange} />
      </Box>
      <Drawer
        classes={{ paper: classes.reportDrawer }}
        anchor="right"
        open={!!selectedCellData}
        ModalProps={{
          BackdropProps: {
            classes: {
              root: classes.testBackdrop,
            },
          },
        }}
        disableEnforceFocus
        onClose={() => {
          setSelectedCellData(undefined);
        }}
      >
        <Box
          width={
            isDrawerExpanded
              ? MODEL_PERFORMANCE_DETAIL_DRAWER_EXPANDED_WIDTH
              : MODEL_PERFORMANCE_DETAIL_DRAWER_WIDTH
          }
          height="100%"
        >
          {selectedCellData && (
            <ModelDetailsPanel
              key={`${selectedCellData.model?.id}-${selectedCellData.threshold}-${selectedCellData.evaluationSet?.id}`}
              model={selectedCellData.model}
              datasetVersion={datasetVersionsMap[selectedCellData.model.datasetVersionId!]}
              evaluationSet={selectedCellData.evaluationSet}
              threshold={selectedCellData.threshold}
              trainDevTestColumnEvaluationSets={trainDevTestColumnEvaluationSets}
              otherEvaluationSets={otherEvaluationSets}
              onChangeEvaluationSet={evaluationSet => {
                setSelectedCellData(data => ({
                  ...data!,
                  evaluationSet,
                }));
              }}
              onClose={() => {
                setSelectedCellData(undefined);
              }}
              allowExpand
              isExpanded={isDrawerExpanded}
              onExpandToggle={() => {
                setIsDrawerExpanded(!isDrawerExpanded);
              }}
            />
          )}
        </Box>
      </Drawer>
      <AddEvaluationSetDialog
        open={addEvaluationSetDialogOpen}
        handleClose={() => setAddEvaluationSetDialogOpen(false)}
      />
    </>
  );
};

export default ModelPerformanceTable;
